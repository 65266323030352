<template>
    <svg
        class="w-8 md:w-10 h-auto ml-2"
        width="38"
        height="37"
        viewBox="0 0 38 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="0.5" width="38" height="36" rx="4" fill="#F1F5F8" />
        <path
            d="M24 16.5L19 21.5L14 16.5"
            stroke="#1B212D"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
